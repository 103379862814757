<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/company/aboutus.jpg');">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level title-heading">
          <h1 class="text-white title-dark"> Aboutus </h1>
          <p class="text-white-50 para-desc mb-0 mx-auto">Start working with AutonomicsNow that can provide everything you
            need to generate awareness, drive traffic, connect.</p>
          <div class="page-next">
           
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<section class="section">
  <div class="container">
    <div class="row align-items-center" id="counter">
      <div class="col-md-6">
        <img src="assets/images/company/about2.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="ms-lg-4">
          <div class="d-flex mb-4">
            <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold" [CountTo]="15" [from]="0"
                [duration]="4"></span>+</span>
            <span class="h6 align-self-end ms-2">Years <br> Experience</span>
          </div>
          <div class="section-title">
            <h4 class="title mb-4">Who we are ?</h4>
            <p class="text-muted">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide
              everything you need to generate awareness, drive traffic, connect. Dummy text is text that is used in the
              publishing industry or by web designers to occupy the space which will later be filled with 'real'
              content. This is required when, for example, the final text is not yet available. Dummy texts have been in
              use by typesetters since the 16th century.</p>
            <a href="javascript:void(0)" class="btn btn-primary mt-3">Contact us</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  
</section>
<!--end section-->

