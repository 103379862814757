<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background: url('assets/images/3.jpg') center center;" id="home">
  <div class="container">
    <div class="row position-relative align-items-center pt-4">
      <div class="col-lg-7 offset-lg-5">
        <div class="title-heading studio-home rounded bg-white shadow mt-5">
          <h1 class="heading mb-3">Present Your Work With <span class="text-primary">Landrick</span> Studio</h1>
          <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap v5 html page.</p>
          <div class="mt-4">
            <a routerLink="/contact" class="btn btn-primary mt-2 me-2"><i class="uil uil-phone"></i> Contact us
              </a>
            
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->





<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->

<!-- Login Modal start -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">15 Days Free Trail</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-content rounded shadow border-0">
    <div class="modal-body p-0">
      <div class="container-fluid px-0">
        <div class="row align-items-center g-0">
          <div class="col-lg-12">
            <form class="login-form p-4">
              <div class="row">

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Name <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input type="text" class="form-control ps-5" placeholder="Name" name="name" required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12">
                  <div class="mb-3">
                    <label class="form-label">Password <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="key" class="fea icon-sm icons"></i-feather>
                      <input type="password" class="form-control ps-5" placeholder="Password" required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12 mb-0">
                  <div class="d-grid">
                    <button class="btn btn-primary">Login</button>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </div>
  </div>
</ng-template>
<!-- Login Modal Content End -->