<div *ngIf="!hideFooter">
    <div>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="#" class="logo-footer">
                            <img src="assets/images/aologo-dark.png" height="36" alt="">
                        </a>
                        <p class="mt-4">Start working with Landrick that can provide everything you need to generate
                            awareness, drive traffic, connect.</p>
                        
                        <!--end icon-->
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                     
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/about-us" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    About us</a></li>
                            <li><a routerLink="/services" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Services</a></li>
                                    <li><a routerLink="/contact" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Contact</a></li>
                           
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/terms-of-services" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                    Terms of Services</a></li>
                            <li><a routerLink="/privacy-policy" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Privacy Policy</a></li>
                         
                        </ul>
                    </div>
                    <!--end col-->

                
                   
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
    </div>
</div>
<!--end footer-->
<footer class="footer footer-bar">
    <div class="container text-center">
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="text-sm-start">
                    <p class="mb-0">
                      &copy;
                 {{year}}
                         AutonomicsNow
                    </p>
                </div>
            </div>
            <!--end col-->


            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</footer>
<!--end footer-->