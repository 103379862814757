<!-- Hero Start -->
<section class="vh-100 d-flex align-items-center"
  style="background: url('assets/images/contact-detail.jpg') center center;">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 offset-lg-6 col-md-7 offset-md-5">
        <div class="title-heading mt-5 pt-4">
          <h1 class="heading">Let's talk !</h1>
          <p class="text-dark">Start working with <span class="text-primary fw-bold">AutonomicsNow</span> </p>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="mail" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Email</h5>
              <a href="mailto:contact@autonomicsnow.ro" class="text-primary">contact@autonomicsnow.ro</a>
            </div>
          </div>

         

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="map-pin" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h5 class="title fw-bold mb-0">Location</h5><span>
                Str. Putul lui Zamfir 39, Et. 2, Sector 1, 011682, Bucuresti, Romania
              </span>
              <a (click)="mapView(content)" data-type="iframe" class="video-play-icon text-primary">View on
                Google map</a>
              <ng-template #content let-modal>
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2847.635777454922!2d26.095781750945335!3d44.46113920772593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1f8a7f26e802b%3A0x874c20cfc54c94a7!2sStrada%20Pu%C8%9Bul%20lui%20Zamfir%2039%2C%20Bucure%C8%99ti!5e0!3m2!1sen!2sro!4v1637307792009!5m2!1sen!2sro"
                  width="750"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
              ></iframe>
                </div>
              </ng-template>
            </div>
          </div>
          
          <!--end icon-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->