<!-- TAGLINE START-->
<div class="tagline bg-light" *ngIf="sliderTopbar == true">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="text-slider">
          <ul class="list-inline mb-0 move-text">
            <li class="list-inline-item px-2 mb-0"><b>EOG</b> $ 55.88 <span class="text-success">+$ 4.62 ( +9.01%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>CHKP</b> $ 120.03 <span class="text-danger">-$ 14.07 ( -10.49%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>FANG</b> $ 63.58 <span class="text-success">+$ 5.17 ( +8.84%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>M</b> $ 14.75 <span class="text-success">+$ 1.05 ( +7.66% )</span>
            </li>
            <li class="list-inline-item px-2 mb-0"><b>PKI</b> $ 139.72 <span class="text-danger">-$ 11.41 ( -7.55%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>ABMD</b> $ 326.23 <span class="text-danger">-$ 21.61 ( -6.21%
                )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>BITCOIN</b> $ 37,471.47 <span class="text-danger">+$ 492.60 (
                +1.33% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>XRP</b> <span> $ 0.39</span><span class="text-muted"> UNCH</span>
            </li>
            <li class="list-inline-item px-2 mb-0"><b>LITECOIN</b> <span> $ 148.67</span><span class="text-danger">-$
                5.58 ( -3.62% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>BITCOIN CASH</b> <span> $ 427.37</span><span
                class="text-danger">-$ 15.98 ( -3.60% )</span></li>
            <li class="list-inline-item px-2 mb-0"><b>ETHEREUM</b> $ 1,647.87 <span class="text-danger">+$ 14.51 (
                +0.89% )</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- TAGLINE END-->

<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="/index" *ngIf="navClass !== 'nav-light'">
        <img src="assets/images/aologo-light.png" class="l-dark logo-light-mode" height="54" alt="">
        <img src="assets/images/aologo-dark.png" class="logo-dark-mode" height="54" alt="">
      </a>

    
    </div>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/" class="nav-link-ref">Company</a></li>
        <li><a routerLink="/services" class="nav-link-ref">Services</a></li>
        <li><a routerLink="/about-us" class="nav-link-ref">About us</a></li>
        <li><a routerLink="/contact" class="nav-link-ref">Contact</a></li>


        <!--li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Docs</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li><a class="nav-link-ref" routerLink="/documentation">Documentation</a></li>
            <li><a class="nav-link-ref" routerLink="/changelog">Changelog</a></li>
            <li><a class="nav-link-ref" routerLink="/components">Components</a></li>
            <li><a class="nav-link-ref" routerLink="/widget">Widget</a></li>
          </ul>
        </li>-->
      </ul>
      <!--end navigation menu-->
      <div class="buy-menu-btn d-none">
        <a href="javascript: void(0);" target="_blank" class="btn btn-primary">Buy Now</a>
      </div>
      <!--end login button-->
      <!--end login button-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->