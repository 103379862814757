import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})

/***
 * Index Studio Component
 */
export class IndexComponent implements OnInit {
  navClass = 'bg-white';

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  /***
   * Login Model open
   */
  open(content) {
    this.modalService.open(content, { centered: true });
  }

}
