import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import{AboutUsComponent}from './core/components/about-us/about-us.component';
import { ContactComponent } from './core/components/contact/contact.component';
import { ServicesComponent } from './core/components/services/services.component';
import{PrivacyPolicyComponent}from './core/components/privacy-policy/privacy-policy.component';
import { TermsOfServicesComponent } from './core/components/terms-of-services/terms-of-services.component';




import { IndexComponent } from './core/components/index/index.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { pathToFileURL } from 'url';


const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: IndexComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'contact',component: ContactComponent}, 
      { path: 'services',component: ServicesComponent},
      { path: 'privacy-policy',component: PrivacyPolicyComponent},
      {path: 'terms-of-services',component: TermsOfServicesComponent}
   
    ]
  }];




@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
    scrollOffset: [0, 0],
    // Enable scrolling to anchors
    anchorScrolling: "enabled"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
